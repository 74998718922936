<template>
  <div id="sellers">

    <!-- mobile -->
    <div id="seller-mobile" v-if="!desktop">
      <v-card outlined>
        <template v-for="(item, index) in items">
          <v-list-item :key="index + 'item'">
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-medium">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip label color="grey lighten-3" class="font-weight-medium px-2">
                <v-icon small color="success" class="mr-1">mdi-check-circle</v-icon>
                {{ item.ingresados }}

                <span class="mx-2 grey--text">|</span>

                <v-icon small color="red" class="mr-1">mdi-close-circle</v-icon>
                {{ item.rechazados }}

                <span class="mx-2 grey--text">|</span>

                <v-icon small class="mr-1">mdi-account</v-icon>
                {{ item.total }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" :key="index" />
        </template>
      </v-card>
    </div>

    <!-- desktop -->
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr class="grey lighten-4">
            <th class="text-subtitle-2 blue-grey--text text--darken-1 text-left" width="15%">
              Embajador
            </th>
            <th class="text-subtitle-2 blue-grey--text text--darken-1 text-left" width="25%"></th>
            <th class="text-subtitle-2 blue-grey--text text--darken-1 text-center" width="10%">Ingresados</th>
            <th class="text-subtitle-2 blue-grey--text text--darken-1 text-center" width="10%">Rechazados</th>
            <th class="text-subtitle-2 blue-grey--text text--darken-1 text-center" width="10%">Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td class="text-subtitle-1 font-weight-medium">{{ item.name }}</td>
            <td>
              <v-progress-linear :value="(item.total * 100) / max" height="6" />
            </td>
            <td class="text-center">
              <v-chip label color="green lighten-5">
                <span class="font-weight-medium green--text text--darken-3">{{ item.ingresados }}</span>
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip label color="red lighten-5">
                <span class="font-weight-medium red--text text--darken-3">{{ item.rechazados }}</span>
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip label>
                <span class="font-weight-medium">{{ item.total }}</span>
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sellers",

  data: () => ({
    items: [],
    max: 0
  }),

  /*  watch: {
      attendees() {
        this.onInit()
      }
    },*/

  computed: {
    ...mapState(['desktop']),
    ...mapState('attendees', ['attendees'])
  },

  methods: {
    onInit() {

      let items = []

      // Creamos un objeto Set para almacenar los distintos sellerId
      const distintosSellerId = this.attendees.reduce((set, asistente) => {
        set.add(asistente.sellerId);
        return set;
      }, new Set());

      // Iteramos sobre los distintos sellerId y contamos los asistentes por vendedor
      distintosSellerId.forEach((sellerId) => {
        const cuenta = this.attendees.filter((asistente) => asistente.sellerId === sellerId).length;
        const ingresados = this.attendees.filter((asistente) => asistente.sellerId === sellerId && asistente.ticket_entered === true).length;
        const rechazados = this.attendees.filter((asistente) => asistente.sellerId === sellerId && asistente.ticket_entered === false).length;

        items.push({
          uid: sellerId,
          name: this.attendees.find((vendedor) => vendedor.sellerId === sellerId).sellerName,
          total: cuenta,
          ingresados: ingresados,
          rechazados: rechazados
        })
      });
      this.items = items.sort((a, b) => {
        return b.total - a.total;
      });

      const objetoMayorTotal = this.items.reduce((anterior, actual) => {
        return actual.total > anterior.total ? actual : anterior;
      });

      this.max = objetoMayorTotal.total
    }
  },

  mounted() {
    if (this.attendees.length > 0) { this.onInit() }
  }
}

</script>

<style scoped lang="scss">
table {
  thead tr {
    th {
      border-bottom: none !important;
    }
  }

  tbody tr {
    height: 60px;
  }
}
</style>