<template>
  <v-card outlined>
    <!-- Items -->
    <template v-for="(item, index) in lists">

      <!-- Item -->
      <v-list-item :key="index">

        <!-- Content -->
        <v-list-item-content>
          <v-list-item-title>
            <span class="text-subtitle-2 font-weight-semibold">{{ item.name }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="text-body-2">{{ countList(item.name) }} Asistentes</span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <!-- Action -->
        <v-list-item-action>
          <v-btn icon v-if="index !== 0" @click="deleteItem(item.docId, index)" :loading="loading && index === i">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <!-- Divider -->
      <v-divider v-if="index < lists.length - 1" :key="`0-${index}`" />
    </template>
    <!-- ./ -->
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["eventId"],

  data: () => ({
    i: null,
    loading: false,
  }),

  computed: {
    ...mapState("lists", ["lists"]),
    ...mapState("attendees", ["attendees"]),
  },

  methods: {
    ...mapActions("lists", ["delete"]),

    async deleteItem(value, index) {
      this.i = index;
      this.loading = true;
      await this.delete({ docId: value, eventId: this.eventId });
      this.i = null;
      this.loading = false;
    },

    countList(name) {
      return this.attendees.filter(asistente => asistente.list === name).length;
    }
  },

  beforeDestroy() {
    this.$store.commit("lists/setLists", [])
  },
};
</script>