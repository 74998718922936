<template>
  <v-form ref="form" @submit.prevent="onSubmit" class="pt-3">
    <!-- Step 01: Add  -->
    <div class="step-01" v-if="!stepValid">
      <v-select :items="lists" item-text="name" outlined dense placeholder="Seleccionar Lista" v-model="list"
        @change="onSelect" v-if="user.role === 'owner'" :rules="[rules.required]" />
      <v-textarea outlined :disabled="!list" placeholder="Agregar Asistentes" v-model="items" required hide-details
        :rules="[rules.required]" />

      <!--  -->
      <v-row class="mt-4">
        <v-col cols="5">
          <v-btn @click="onClean" depressed block large> Limpiar </v-btn>
        </v-col>
        <v-col>
          <v-btn large block depressed class="primary" :loading="loading" @click="validateAttendees"
            :disabled="attendees.length === 0">
            Validar Asistentes
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Step 02: Saved -->
    <div class="step-02" v-else>
      <v-card class="mb-6" outlined v-if="attendees.length > 0">
        <v-system-bar window>
          <span>{{ attendees.length }} Asistentes</span>
        </v-system-bar>
        <template v-for="(item, index) in attendees">
          <!-- Attendee -->
          <v-list-item :key="item.rut" two-line>
            <!-- icon -->
            <v-list-item-avatar>
              <v-icon dark :class="vRegister(item.add, item.isValid)">
                mdi-account-outline
              </v-icon>
            </v-list-item-avatar>
            <!-- content -->
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" v-text="item.name" />
              <!-- rut -->
              <v-list-item-subtitle class="font-weight-medium" :class="item.isValid ? null : 'red--text'"
                v-text="item.isValid ? item.rut : 'Rut no valido'" />
              <!-- reason -->
              <v-list-item-subtitle v-if="!item.add">
                <span class="red--text font-weight-medium">
                  {{ item.reason }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- ./Attendee -->

          <v-divider v-if="index < attendees.length - 1" :key="index" />
        </template>
      </v-card>
      <v-row>
        <v-col cols="5">
          <v-btn @click="onBack" large depressed block>Regresar</v-btn>
        </v-col>
        <v-col>
          <v-btn block large depressed type="submit" class="primary" :loading="loading" :disabled="loading">
            Agregar Asistentes
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import _ from "lodash";
import Rut from "rutjs";
import { rulesForm } from "@/assets/settings.js";
import { mapActions, mapState } from "vuex";

export default {
  props: ["reset", "event"],

  data: () => ({
    list: null,
    rules: rulesForm[0],
    items: [],
    attendees: [],
    loading: false,
    stepValid: null,
  }),

  watch: {
    reset() {
      this.onReset();
    },

    items() {
      const items = [];
      var array = _.split(this.items, "\n");
      array = _.reject(array, _.isEmpty);

      array.forEach((element) => {
        // [create-array]
        var item = _.split(element, " ");
        item = _.reject(item, _.isEmpty);

        // [selected-rut & Valid]
        var rut = new Rut(String(_.last(item)));

        // [name]
        var name = item.slice(0, -1);
        name = _.map(name).join(" ");
        name = name.replace(/[^a-zA-Z ]/g, "");
        name = name.trim();

        // ADD: DeadLine
        let deadLine = _.find(this.lists, { name: this.list }).date;

        items.push({
          isValid: rut.isValid,
          name: name,
          rut: rut.getNiceRut(false),
          activeTicket: true,
          eventId: this.event.uid,
          sellerId: this.user.uid,
          sellerName: this.user.displayName,
          disabled: false,
          list: this.list,
          deadLine: deadLine ? deadLine : null, // ? Fixed
        });
      });

      this.attendees = _.uniqBy(items, "rut");
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("lists", ["lists"]),
  },

  methods: {
    ...mapActions("attendees", ["create", "findAttendeeInEvent"]),

    vRegister(add, rut) {
      if (!rut) {
        return "warning";
      } else {
        if (add) {
          return "success";
        } else {
          return "error";
        }
      }
    },

    onClean() {
      this.items = [];
    },

    itemDelete(index) {
      this.attendees.splice(index, 1);
    },

    async validateAttendees() {
      this.loading = true;

      // 00. const
      const array = [];

      // 01. verify exist list
      await Promise.all(
        this.attendees.map(async (i) => {
          const ticketId = `${i.rut}_${i.eventId}`;
          const attendee = await this.findAttendeeInEvent(ticketId);

          // 01.1 verify exist.
          if (attendee) {
            // a) general to general
            if (attendee.list === "General" && this.list === "General") {
              array.push(
                _.merge(i, { add: false, reason: "Existe en General" })
              );
            }

            // b) general to special
            if (attendee.list === "General" && this.list !== "General") {
              array.push(
                _.merge(i, {
                  add: true,
                  reason: "Agregar a una lista especial",
                })
              );
            }

            // c) special
            if (attendee.list !== "General") {
              array.push(
                _.merge(i, { add: false, reason: "Existe en una lista" })
              );
            }
          } else {
            array.push(
              _.merge(i, { add: true, reason: "Agregar nuevo asistente" })
            );
          }
        })
      );

      this.attendees = array;
      this.loading = false;
      this.stepValid = true;
    },

    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const eventId = this.$route.params.uid;
        const array = _.filter(this.attendees, { add: true, isValid: true });

        await this.create({ data: array, event: eventId });

        this.loading = false;
        this.onReset();
      }
    },

    onReset() {
      this.$emit("close");
      this.items = [];
      this.stepValid = null;
      this.$refs.form.resetValidation();
      if (this.user.role === "owner") {
        this.list = null;
      }
    },

    onSelect() {
      this.items = [];
    },

    onBack() {
      this.stepValid = null;
    },
  },

  mounted() {
    if (this.user.role !== "owner") {
      this.list = this.$store.state.lists.lists[0].name;
    }
  },
};
</script>