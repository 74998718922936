<template>
    <div class="seller-top">
        <template v-for="(item, index) in sellers">
            <v-list-item :key="index + 'seller'">
                <v-list-item-content>
                    <v-list-item-title> {{ `${index + 1}. ${item.name}` }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip label color="grey lighten-3">
                        <span class="font-weight-medium">{{ item.total}}</span>
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < sellers.length - 1" :key="index"/>
        </template>


    </div>
</template>

<script>
export default {
  name: "SellerTop.vue",

  props: ['data'],

  data: () => ({
    sellers: [],
    attendees: []
  }),

  watch: {
    data(e) {
      this.attendees = e
      this.onInit()
    }
  },

  methods: {
    onInit() {

      let items = []

      // Creamos un objeto Set para almacenar los distintos sellerId
      const distintosSellerId = this.attendees.reduce((set, asistente) => {
        set.add(asistente.sellerId);
        return set;
      }, new Set());

      // Iteramos sobre los distintos sellerId y contamos los asistentes por vendedor
      distintosSellerId.forEach((sellerId) => {
        const cuenta = this.attendees.filter((asistente) => asistente.sellerId === sellerId).length;
        const ingresados = this.attendees.filter((asistente) => asistente.sellerId === sellerId && asistente.ticket_entered === true).length;
        const rechazados = this.attendees.filter((asistente) => asistente.sellerId === sellerId && asistente.ticket_entered === false).length;

        items.push({
          uid: sellerId,
          name: this.attendees.find((vendedor) => vendedor.sellerId === sellerId).sellerName,
          total: cuenta,
          ingresados: ingresados,
          rechazados: rechazados
        })
      });
      items = items.sort((a, b) => {
        return b.total - a.total;
      });

      this.sellers = items.slice(0, 5)

    }
  },
}
</script>

<style scoped>

</style>