<template>
  <v-dialog :value="value" max-width="450" persistent :fullscreen="!desktop" overlay-color="black" overlay-opacity="0.8" transition="slide-x-reverse-transition">
    <v-card :tile="!desktop">
      <!-- [title] -->
      <v-card-title>
        <span class="text-subtitle-1 | font-weight-medium">
          Crear Asistente
        </span>
        <v-spacer />
        <!-- <v-icon @click="$emit('close')">mdi-close</v-icon> -->
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>

      <!-- [tabs] -->
      <v-divider />
      <v-tabs fixed-tabs>
        <v-tab @click="model = false">Individual</v-tab>
        <v-tab @click="model = true">Grupal</v-tab>
      </v-tabs>
      <v-divider />

      <!-- [content] -->
      <v-card-text class="pt-4">
        <!-- (individual) -->
        <create-attendee
          v-if="!model"
          :event="event"
          @close="$emit('close')"
          :reset="reset"
        />
        <create-attendee-group
          v-else
          :event="event"
          @close="$emit('close')"
          :reset="reset"
        />
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import CreateAttendee from "./AttendeeAdd.vue";
import CreateAttendeeGroup from "./AttendeeGroup.vue";

export default {
  props: ["value", "event"],

  components: { CreateAttendee, CreateAttendeeGroup },

  data: () => ({
    reset: false,
    model: false,
  }),

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    close() {
      this.reset = !this.reset;
    },
  },
};
</script>