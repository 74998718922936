<template>
  <!-- [desktop] -->
    <v-data-table
            v-if="desktop"
            :headers="headersTable"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="table-1"
    >
        <!-- (empty) -->
        <template v-slot:no-data> Sin Asistentes</template>
        <template v-slot:no-results> Sin Resultados</template>

        <!-- (name) -->
        <template v-slot:[`item.name`]="{ item }">
            <td class="text-subtitle-1 font-weight-medium | text-capitalize">
                {{ item.name }}
            </td>
        </template>

        <!-- (rut) -->
        <template v-slot:[`item.rut`]="{ item }">
            <td class="text-subtitle-1 font-weight-medium | text-capitalize">
                {{ item.rut }}
            </td>
        </template>

        <!-- (list) -->
        <template v-slot:[`item.list`]="{ item }">
            <td class="text-capitalize">
                <v-chip label color="grey lighten-4">
                    <span class="font-weight-medium text-subtitle-2">{{ item.list }}</span>
                </v-chip>
            </td>
        </template>

        <!-- (activeTicket) -->
        <template v-slot:[`item.activeTicket`]="{ item }">
            <td>
                <v-chip v-if="item.activeTicket" color="grey lighten-3" label style="width: 100px"
                        class="justify-center">
                    <span class="font-weight-medium text-subtitle-2">Habilitado</span>
                </v-chip>
                <v-chip
                        :color="item.ticket_entered ? 'green darken-1' : 'red darken-1'"
                        v-else
                        dark
                        label
                        style="width: 100px"
                        class="justify-center"
                >
                    <span class="font-weight-medium">{{ item.ticket_entered ? "Aceptado" : "Rechazado" }}</span>
                </v-chip>
            </td>
        </template>

        <!-- (delete) -->
        <template v-slot:[`item.actions`]="{ item }">
            <td class="text-end">
                <v-btn
                        :disabled="!enable"
                        :loading="loading[item.rut]"
                        icon
                        small
                        @click="onDelete(item.rut)"
                >
                    <v-icon color="red"> mdi-trash-can-outline</v-icon>
                </v-btn>
            </td>
        </template>
    </v-data-table>

  <!-- [mobile] -->
    <v-data-table
            :search="search"
            v-else
            :headers="headers"
            :items="items"
            hide-default-header
    >
        <template v-slot:body="{ items }">
            <!-- (items) -->
            <tbody v-if="items.length > 0">
            <tr v-for="(item, index) in items" :key="index" class="d-flex">
                <td style="width: 100%; height: 62px">
                    <v-list-item class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="text-capitalize | font-weight-medium">
                                {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item.rut }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon v-if="item.activeTicket" color="grey darken-2">
                                mdi-account-outline
                            </v-icon>
                            <v-icon
                                    v-else
                                    :color="item.ticket_entered ? 'green accent-4' : 'red'"
                            >
                                {{
                                `mdi-account-${
                                    item.ticket_entered ? "check-outline" : "cancel-outline"
                                }`
                                }}
                            </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </td>
            </tr>
            </tbody>
            <!-- (empty) -->
            <tbody v-else>
            <tr>
                <div class="text-center">Asistentes</div>
            </tr>
            </tbody>
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  props: ["items", "enable", "search"],

  data: () => ({
    loading: false,
    headers: [{text: "Asistente", value: "name"}],
    headersTable: [
      {
        text: "Nombre",
        value: "name",
        width: "25%",
        class: 'text-subtitle-2 blue-grey--text text--darken-1 grey lighten-4'
      },
      {
        text: "Rut",
        value: "rut",
        width: "18%",
        sortable: false,
        class: 'text-subtitle-2 blue-grey--text text--darken-1 grey lighten-4'
      },
      {
        text: "Lista",
        value: "list",
        width: "15%",
        class: 'text-subtitle-2 blue-grey--text text--darken-1 grey lighten-4'
      },
      {
        text: "Responsable",
        value: "sellerName",
        class: 'text-subtitle-2 blue-grey--text text--darken-1 grey lighten-4'
      },
      {text: "Ticket", value: "activeTicket", class: "text-subtitle-2 blue-grey--text text--darken-1 grey lighten-4"},
      {
        text: "Eliminar",
        value: "actions",
        class: "text-end text-subtitle-2 blue-grey--text text--darken-1 grey lighten-4",
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    ...mapActions("attendees", ["delete"]),

    async onDelete(item) {
      if (confirm("Seguro que quieres eliminar")) {
        this.spinner(item, true);
        await this.delete(`${item}_${this.$route.params.uid}`);
        this.spinner(item.rut, false);
      }
    },

    spinner(i, v) {
      this.loading = [];
      this.loading[i] = v;
    },
  },
};
</script>

<style scoped lang="scss">
.table-1 {
  tbody td {
    height: 60px !important;
  }
}
</style>